import {
  Box,
  BoxProps,
  Heading,
  Text,
  ThemingProps,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export const Title: FunctionComponent<BoxProps & ThemingProps> = ({
  ...props
}) => {
  const onMobile = useBreakpointValue({ base: true, lg: false });

  props = {
    ...(!onMobile && { variant: 'sticky' }),
    ...props,
  };

  const styles = useMultiStyleConfig('Title', props);

  return (
    <Box sx={styles.box} {...props}>
      <Heading as="h1" sx={styles.heading}>
        CO2{' '}
        <Text as="span" sx={styles.subtitle}>
          KALKYLATOR
        </Text>
      </Heading>
    </Box>
  );
};
