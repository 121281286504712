import {ApiSeaDistanceResponse, Emission, Transport} from '../types';
import {API} from './API';
import {HTTP} from './HTTP';

const calculatePreCarriage = async (
  preCarriagePlaceId: string,
  weight: number,
  originPortLat: string,
  originPortLng: string,
  sessionToken?: string,
) => {
  const preCar = await HTTP().get(API.getPlaces.base, {
    params: {
      sessionToken,
      placeId: preCarriagePlaceId,
    },
  });

  if (preCar.status === 200) {
    const preCarriageDistance = await HTTP().get(API.getPlaneDistance.base, {
      params: {
        originLat: preCar?.data.result.geometry?.location?.lat,
        originLng: preCar?.data?.result?.geometry?.location?.lng,
        destLat: Number.parseFloat(originPortLat),
        destLng: Number.parseFloat(originPortLng),
      },
    });

    return (
      (((weight ?? 0) / 1000) *
        (preCarriageDistance.data.distance / 1000) *
        62) /
      // We need to divide by 1.000.000 to get tons, since the formula returns KG otherwise
      1_000_000
    );
  }
  return 0;
};

const calculateOnCarriage = async (
  onCarriagePlaceId: string,
  weight: number,
  destinationPortLat: string,
  destinationPortLng: string,
  sessionToken?: string,
) => {
  const onCar = await HTTP().get(API.getPlaces.base, {
    params: {
      sessionToken,
      placeId: onCarriagePlaceId,
    },
  });

  if (onCar.status === 200) {
    const onCarriageDistance = await HTTP().get(API.getPlaneDistance.base, {
      params: {
        originLat: onCar?.data.result?.geometry?.location?.lat,
        originLng: onCar?.data.result?.geometry?.location?.lng,
        destLat: Number.parseFloat(destinationPortLat),
        destLng: Number.parseFloat(destinationPortLng),
      },
    });

    return (
      (((weight ?? 0) / 1000) *
        (onCarriageDistance.data.distance / 1000) *
        62) /
      // We need to divide by 1.000.000 to get tons, since the formula returns KG otherwise
      1_000_000
    );
  }
  return 0;
};

export const calculateEmission = async (
  transport: Transport,
  weight: number,
  originPortName: string,
  destinationPortName: string,
  originPortLat?: string,
  originPortLng?: string,
  destinationPortLat?: string,
  destinationPortLng?: string,
  sessionToken?: string,
  preCarriagePlaceId?: string,
  onCarriagePlaceId?: string,
): Promise<Emission> => {
  let mainRoute = 0;
  let preCarriage = 0;
  let onCarriage = 0;

  if (transport === Transport.Sea) {
    const seaResponse = await HTTP().get<ApiSeaDistanceResponse>(
      API.getSeaDistance.base,
      {
        params: {
          origin: originPortName,
          destination: destinationPortName,
        },
      },
    );
    if (seaResponse.status === 200) {
      if (seaResponse.data.distance === -1) {
        throw new Error(
            'Tyvärr, det finns ingen sjöväg mellan de hamnar du valde. Försök med en annan kombination?',
        );
      }
      mainRoute =
        (((weight ?? 0) / 1000) * seaResponse.data.distance * 1.852 * 8) /
        1_000_000;
    }
  } else {
    if (
      originPortLat &&
      originPortLng &&
      destinationPortLat &&
      destinationPortLng
    ) {
      const airResponse = await HTTP().get(API.getPlaneDistance.base, {
        params: {
          originLat: Number.parseFloat(originPortLat),
          originLng: Number.parseFloat(originPortLng),
          destLat: Number.parseFloat(destinationPortLat),
          destLng: Number.parseFloat(destinationPortLng),
        },
      });

      if (airResponse.status === 200) {
        mainRoute =
          (((weight ?? 0) / 1000) * (airResponse.data.distance / 1000) * 500) /
          // We need to divide by 1.000.000 to get tons, since the formula returns KG otherwise
          1_000_000;
      }
    }
  }

  if (preCarriagePlaceId && originPortLat && originPortLng) {
    preCarriage = await calculatePreCarriage(
      preCarriagePlaceId,
      weight,
      originPortLat,
      originPortLng,
      sessionToken,
    );
  }

  if (onCarriagePlaceId && destinationPortLat && destinationPortLng) {
    onCarriage = await calculateOnCarriage(
      onCarriagePlaceId,
      weight,
      destinationPortLat,
      destinationPortLng,
      sessionToken,
    );
  }
  return {
    main: mainRoute,
    onCarriage,
    preCarriage,
    total: mainRoute + onCarriage + preCarriage,
  };
};
