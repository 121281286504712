import '@fontsource/roboto';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import { Button, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { Form } from './components/Form';
import { Header } from './components/Header';
import { Result } from './components/Result';
import { Title } from './components/Title';
import { Emission } from './types';

export const App: FunctionComponent = () => {
  const [emission, setEmission] = useState<Emission | Error | null>(null);

  return (
    <Stack spacing={0}>
      <Header />
      <Stack position="relative" layerStyle="primary">
        <Title layerStyle={{ base: 'primary', lg: 'blue' }} />
        <Stack>
          <Form layerStyle={['column', 'thin']} setEmission={setEmission} />
        </Stack>
      </Stack>
      <Stack>
        {emission instanceof Error ? (
          <Stack layerStyle={['column', 'thin']} spacing={10}>
            <Text fontWeight="normal">{emission.message}</Text>
          </Stack>
        ) : (
          emission && (
            <Stack layerStyle={['column', 'thin']} spacing={10}>
              <Result result={emission} />
            </Stack>
          )
        )}
      </Stack>
      <Stack layerStyle="light">
        <Stack layerStyle={['column', 'thin']} spacing={10}>
          <Text align="center">
          Kan vi transportera något åt dig? Följ länken nedan och få ett omedelbart prisförslag!
          </Text>
          <Button
            as="a"
            variant="solid"
            colorScheme="blue"
            href="https://scanlog.qwykportals.com/"
            target="_blank"
            isFullWidth
          >
            Få prisförslag
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
