import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 2,
  },
  variants: {
    solid: {
      p: 6,
    },
    delete: (props) => ({
      ...props.theme.components.Button.variants.ghost(props),
      fontSize: 'lg',
      p: 1,

      _hover: {
        backgroundColor: 'transparent',
        color: 'green.100',
      },
      _focus: {
        backgroundColor: 'transparent',
        border: '2px solid white',
        shadow: 'none',
      },
      _active: {
        backgroundColor: 'transparent',
      },
    }),
    formlink: (props) => ({
      ...props.theme.components.Button.variants.link(props),
      alignSelf: 'flex-end',
      color: 'green.100',
      fontSize: 'sm',

      _hover: {
        textDecoration: 'none',
        color: 'white',
      },
    }),
  },
};
