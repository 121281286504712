import {HStack, Image, Link, StackProps, Text, ThemingProps,} from '@chakra-ui/react';
import {FunctionComponent} from 'react';

export const Header: FunctionComponent<StackProps & ThemingProps> = ({
  ...props
}) => (
  <HStack justify="space-between" layerStyle="column" {...props}>
    <Image src="images/scanlog-logo.png" alt="Scanlog" height={45} />
    <Link href="http://www.scanlog.se/" fontSize="sm">
        Scanlogs webbplats{' '}
      <Text as="span" fontWeight="normal">
        &#10132;
      </Text>
    </Link>
  </HStack>
);
