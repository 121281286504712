import type {InputProps} from '@chakra-ui/react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    List,
    ListItem,
    Spinner,
    Text,
    useMultiStyleConfig,
} from '@chakra-ui/react';
import Downshift from 'downshift';
import type {FieldError, RegisterOptions, UseFormRegister,} from 'react-hook-form';
import {IFormValues} from './Form';

export interface AutoCompleteProps<T> extends InputProps {
  label?: string | React.ReactElement;
  register: UseFormRegister<IFormValues>;
  validation?: RegisterOptions;
  error: FieldError | undefined;
  accessor: keyof IFormValues;
  items?: T[] | null;
  inputValue?: string;
  isLoading: boolean;
  poweredByGoogle?: boolean;
  itemToString: (item: T | null) => string;
  onSelectItem?: (selectedItem: T | null) => void;
  onInputValueChange?: (input: string) => void;
}

export const AutoComplete = <T,>({
  label,
  accessor,
  register,
  validation,
  error,
  isLoading,
  items,
  inputValue: propsInputValue,
  poweredByGoogle,
  itemToString,
  onSelectItem,
  onInputValueChange,
  ...restProps
}: AutoCompleteProps<T>) => {
  const styles = useMultiStyleConfig('Autocomplete', {
    ...(poweredByGoogle && { variant: 'google' }),
    ...restProps,
  });

  return (
    <Downshift
      onChange={onSelectItem}
      onInputValueChange={onInputValueChange}
      itemToString={itemToString}
      inputValue={propsInputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        inputValue,
        isOpen,
        highlightedIndex,
      }) => {
        const { as, size, onChange, onBlur, ...restInputProps } =
          getInputProps();

        return (
          <FormControl
            isInvalid={!!error}
            alignItems="center"
            {...getRootProps()}
          >
            {label && (
              <FormLabel
                htmlFor={accessor}
                {...getLabelProps()}
                flexBasis={{ md: 130 }}
              >
                {label}
              </FormLabel>
            )}
            <Box flexGrow={2} position="relative">
              <Input
                id={accessor}
                variant="filled"
                {...register(accessor, {
                  onChange,
                  onBlur,
                  ...validation,
                })}
                {...restInputProps}
                {...restProps}
                as={as}
                size={size}
              />
              {isOpen && inputValue !== '' && (
                <Box sx={styles.dropdown}>
                  {!items || isLoading ? (
                    <Spinner sx={styles.spinner} />
                  ) : items.length > 0 ? (
                    <List sx={styles.list} {...getMenuProps()}>
                      {items.map((item, index) => (
                        <ListItem
                          sx={styles.listItem}
                          backgroundColor={
                            highlightedIndex === index
                              ? 'green.200'
                              : 'transparent'
                          }
                          key={itemToString(item)}
                          {...getItemProps({
                            index,
                            item,
                          })}
                        >
                          {itemToString(item)}
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                      <Text sx={styles.noResult}>Inget resultat hittades.</Text>
                  )}
                </Box>
              )}
            </Box>
          </FormControl>
        );
      }}
    </Downshift>
  );
};
