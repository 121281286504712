import {Divider, HStack, Stack, StackProps, Text, ThemingProps, useMultiStyleConfig,} from '@chakra-ui/react';
import {FunctionComponent} from 'react';
import {Emission} from '../types';

const ROUNDING_FACTOR = 1000;

export interface ResultProps extends StackProps, ThemingProps {
  result: Emission;
}

const renderSum = (sum: number) => {
  if (sum > 0 && sum < 0.01) {
    return '< 0.01';
  }
  return (Math.round(sum * ROUNDING_FACTOR) / ROUNDING_FACTOR).toFixed(2);
};

export const Result: FunctionComponent<ResultProps> = ({
  result,
  ...restProps
}) => {
  const styles = useMultiStyleConfig('Result', { ...restProps });

  const renderDetail = (label: string, value: number) => (
    <HStack sx={styles.detail}>
      <Text sx={styles.label}>{label}</Text>
      <Text sx={styles.value} {...(!value && { layerStyle: 'disabledText' })}>
        {renderSum(value)} t
      </Text>
    </HStack>
  );
  return (
    <Stack spacing={10} {...restProps}>
      <Stack spacing={5}>
        <HStack sx={styles.total}>
          <Text>Totalt* CO2-utsläpp</Text>
          <Text
            sx={styles.totalValue}
            {...(!result.total && { layerStyle: 'disabledText' })}
          >
            {renderSum(result.total)}&nbsp;t
          </Text>
        </HStack>
        <Divider />
        <Stack spacing={3}>
          {renderDetail('Pre Carriage', result.preCarriage ?? 0)}
          {renderDetail('Main Route', result.main)}
          {renderDetail('On Carriage', result.onCarriage ?? 0)}
        </Stack>
      </Stack>
      <Text sx={styles.hint}>
          *Detta resultat är en genomsnittlig CO2-beräkning baserad på uppskattningar och typiska transportvägar, med
          förbehåll för en slutlig bokning och ruttbekräftelse.
      </Text>
    </Stack>
  );
};
